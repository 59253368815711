import React from 'react';

// Material
import { MenuItem, TextField } from '@material-ui/core';

// Elemente
import Icon from '../icons';

// Material
import { withStyles, fade } from '@material-ui/core/styles';


const MStyle = theme => ({
    Input: {
        '& .Mui-focused': {
            color: theme.palette.type === "dark" ? "white" : "black",
        },
        '& .Mui-disabled': {
            color: theme.palette.type === "dark" ? fade("#fafafa", 0.7) : fade("#212121", 0.65)
        },
    }
});
class SelectMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || props.defaultValue
        };

    }

    onChangeSelect(e) {
        this.setState({ value: e.target.value });
        this.props.onChange(e);
    }

    render() {

        const { select, required, disabled, placeholder, label, name, classes, description, inputVariant } = this.props;
        let value = this.state.value;
        let item = select.find((item) => String(item.value).toLowerCase() === String(value).toLowerCase());
        if (item) value = item.value;
        else value = "";

        const pflichtfeld = !value && required ? <small key={"smallKey_" + value} className="text-danger p-0 m-0"><b>{"Pflichtfeld"}! </b></small> : "";

        let kostenText = "";

        if (name === "TYP" && this.state.value === "Lieferung") {
            kostenText = "Lieferkosten +2,50 €";
        }

        return <>


            <TextField
                select
                fullWidth
                variant={inputVariant || "outlined"}

                required={required}
                disabled={disabled}
                name={name}
                label={label || name}
                placeholder={placeholder}
                className={classes.Input}
                helperText={[pflichtfeld, description, kostenText]}
                value={value || ""}
                onChange={(e) => this.onChangeSelect(e)}
            >

                {required ? "" :
                    <MenuItem className="border-bottom " value={""} ><Icon icon="Close" /> <span >Leer</span></MenuItem>
                }
                {select.map((item, index) => {
                    return <MenuItem key={index} value={item.value} ><Icon icon={item.icon} /> <span >{item.label || item.value}</span></MenuItem>;
                })}
            </TextField>
        </>;
    }
}


export default withStyles(MStyle)(SelectMenu);