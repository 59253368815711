import React from 'react';
import { Zoom } from 'react-slideshow-image';

class Carusel extends React.Component {
    render() {

        let style = Object.assign(this.props.style || {}, { objectFit: "cover" })



        return (<div className="carusel">
            <Zoom
                scale={1}
                duration={3000}
                transitionDuration={500}
                arrows={this.props.arrows || false}
                indicators={this.props.indicators}
            >
                {
                    this.props.bilder.map((each, index) =>
                        <img key={index}
                            style={style}
                            {...this.props}
                            height={this.props.height}
                            width={this.props.width}
                            alt={index}
                            src={each}

                        />)
                }
            </Zoom>
        </div>);
    }
}

export default Carusel;