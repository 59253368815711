import React from 'react';

// Inputs
import Select from './select';
import DateTimeInput from './date/dateTime';
import TextField from './textField';

class Inputs extends React.Component {
    constructor(props) {
        super(props);
        this.inputs = {
            SELECT: Select,
            DATETIME: DateTimeInput,
            TEXT: TextField
        };
    }

    render() {
        let Input = this.inputs[String(this.props.type || "text").toUpperCase()];

        if (!Input) Input = this.inputs.TEXT;

        return <Input {...this.props} />

    }
}

export default Inputs;



