import React from 'react';
import PriceFormat from '../utils/priceFormat';
import TextTransition, { presets } from "react-text-transition";

// Material
import { Button, Card, CardActionArea, CardMedia, CardActions, CardContent, Typography, RadioGroup, Radio, FormControlLabel, CardHeader, FormLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Icon from "./icons";
import SelectContainer from './select';
import Kommentar from './kommentarInput';
import Extras from './extras';


import BILDER from '../css/image/article';
import DEFAULTBILDER from '../css/image/articleSymbol';

const IMAGES = BILDER();
const DEFAULTIMAGES = DEFAULTBILDER();

const MStyle = theme => ({
    Card: {
        // boxShadow: "0px 2px 8px 2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
    },
    CardMedia: {
        objectFit: "contain"
    },
    FormControlLabel: {
        margin: "-8px"
    }
});
const MENUBEILAGE = {
    getrenk: [
        "Limoment Apfel-Rose 0,33l",
        "Limoment Apfel-Minze 0,33l",
        "Limoment Kirsche-Waldmeister 0,33l",
        "Lütts Landlust Apfelschorle 0,33l",
        "Lütts Landlust Rhabarber 0,33l",
        "Lütts Landlust Apfel-Sanddorn 0,33l",
        "Lütts Landlust Stachelbeere 0,33l",
        "Josefs Eistee Granatapfel 0,5l",
        "Josefs Eistee Pfirsich 0,5l",
        "Cola 0,33l",
        "Cola Zero 0,33l",
        "Fanta 0,33l",
        "Sprite 0,33l",
        "Fritz-Kola Original 0,33l",
        "Fritz-Kola Super Zero 0,33l",
        "Fritz-Kola Mischmasch 0,33l",
        "Krombacher Pils alkoholfrei 0,33l",
        "Krombacher Alster alkoholfrei 0,33l",
        "Carolinen feinperlig 0,25l",
        "Carolinen naturell 0,25l",
    ],
    pommes: [
        "Rustico Frites",
        "Dippers"
    ],
    dips: [
        "Tomaten-Pesto",
        "Basilikum-Pesto",
        "Kräuterdip",
        "BBQ",
        "Chili-Cheese",
        "Joppie",
        "Ketchup",
        "Mayonnaise",
        "Curry-Mango"
    ]
}

class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preis: props.article.preis.menu ? props.article.preis.single : props.article.preis,
            buttonInnerHtml: <Icon icon="Add" />,
            article: props.article,
            inputValue: "",
            selectValue: "single",
            disableAdd: false,
            radioValue: "",
        };
        this.values = {
            EXTRAS: [],
            MENUBEILAGE: {},

        };
    }


    componentDidMount() {
        this.checkDisableAdd()
    }

    checkDisableAdd() {
        if (this.state.selectValue === "menu") {
            if (!this.values.MENUBEILAGE.getrenk || !this.values.MENUBEILAGE.pommes || !this.values.MENUBEILAGE.dips) {
                this.setState({ disableAdd: true });
            }
            else this.setState({ disableAdd: false });
        }
        if (this.state.article.dressing) {
            this.setState({ disableAdd: !Boolean(this.state.radioValue) });
        }


    }

    setKommentar(value, name) {
        this.values[name] = value;
    }

    setExtras(extra) {
        let extras = this.values.EXTRAS || [];

        if (!extras.find((item) => item.name === extra.name)) {
            extras.push(extra);
            this.setState({ preis: this.state.preis + extra.preis });
            this.values.EXTRAS = extras;
        } else {
            extras = extras.filter((item) => item.name !== extra.name);
            this.setState({ preis: this.state.preis - extra.preis });
            this.values.EXTRAS = extras;
        }
    }


    setBeilage(value, name) {
        this.values.MENUBEILAGE[name] = value;
        this.checkDisableAdd();
    }


    handleSelect(e) {
        this.values.MENUBEILAGE = {};
        let extraPreise = 0;
        for (const extra of this.values.EXTRAS) {
            extraPreise += extra.preis;
        }
        this.setState({ selectValue: e.target.value, preis: (this.state.article.preis[e.target.value] + extraPreise) }, () => this.checkDisableAdd());
    }

    addWarenkorb() {
        this.setState({ buttonInnerHtml: <span className="animated zoomIn faster"><Icon icon="Check" /></span>, disableAdd: true });
        let article = { ...this.values };
        article.PREIS = this.state.preis;
        article.BESCHREIBUNG = this.state.article.beschreibung;
        article.NAME = this.state.article.name;
        article.ARTIKELNUMMER = this.state.article.artikelnummer;
        article.gruppe = this.state.article.gruppe;
        article.single_menu = this.state.article.preis.menu ? this.state.selectValue : "";

        if (this.state.article.dressing) article.KOMMENTAR = `${this.state.radioValue}. ${article.KOMMENTAR}`;

        this.props.onClick(article);
        setTimeout(() => {
            this.setState({ buttonInnerHtml: <Icon icon="Add" />, disableAdd: false });
        }, 600);
    }


    render() {
        const { article } = this.state;
        const img = getImage(article);
        const icon = getIcon(article.gruppe);

        return (<>
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mt-4 mb-2" >
                <Card elevation={3} className={this.props.classes.Card} style={{ width: "100%", height: "100%" }}>
                    <CardHeader title={<><Icon icon={icon} /> {article.name} </>} />

                    {/* Benutzerdefinierte Bades Text und Style mitgeben */}
                    <div className="badges">
                        {article.badges && article.badges.map((b, i) => {
                            return <div key={i} className="ff-3" style={b.style || {}}>{b.text}</div>
                        })}
                    </div>

                    <CardActionArea>
                        <CardMedia component="img" alt="Contemplative Reptile" height="140" image={img} title={article.name} className={this.props.classes.CardMedia} />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">{article.beschreibung}</Typography>
                            <Typography variant="caption" component="p">{article.beschreibung2}</Typography>
                        </CardContent>
                    </CardActionArea>


                    {this.state.selectValue === "menu" ? <div className="d-flex flex-column pl-2 pr-2">
                        <Typography gutterBottom variant="body2" className={`mt-2 ${this.state.disableAdd ? "text-danger" : "text-success"}`}>Wähle die Beilagen für das Menü aus.</Typography>
                        <SelectContainer onChange={this.setBeilage.bind(this)} label="Getränk" name="getrenk" select={MENUBEILAGE.getrenk} />
                        <SelectContainer onChange={this.setBeilage.bind(this)} label="Pommes" name="pommes" select={MENUBEILAGE.pommes} />
                        <SelectContainer onChange={this.setBeilage.bind(this)} label="Dips" name="dips" select={MENUBEILAGE.dips} />
                    </div>
                        : ""}


                    <Extras article={article} onChange={this.setExtras.bind(this)} />

                    {/* DRESSING kann auch für was anderes verwendet werden  */}
                    {article.dressing && <div className="px-2 mt-3">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{article.dressing.label}</FormLabel>
                            <RadioGroup small row value={this.state.radioValue} onChange={(e) => this.setState({ radioValue: e.target.value }, () => this.checkDisableAdd())}>
                                {article.dressing.items.map((d, i) => <FormControlLabel key={i} value={d} control={<Radio size="small" />} label={d} />)}
                            </RadioGroup>
                        </FormControl>
                    </div>}

                    {article.gruppe !== "getrenk" ? <Kommentar onChange={this.setKommentar.bind(this)} /> : ""}

                    <CardActions >
                        <div className="row m-0 p-0 w-100">
                            {article.preis.menu ?

                                <RadioGroup className="col-7 col-md-4 mt-2" value={this.state.selectValue} onChange={this.handleSelect.bind(this)}>
                                    <FormControlLabel className={this.props.classes.FormControlLabel} value="menu" control={<Radio />} label={<small>Menü</small>} />
                                    <FormControlLabel className={this.props.classes.FormControlLabel} value="single" control={<Radio />} label={<small>Single</small>} />
                                </RadioGroup>

                                : ""}
                            <Button className={`${article.preis.menu ? "col-5 col-md-4" : "col-6"} text-success mt-2`} variant="text" size="large">
                                <TextTransition delay={300} text={PriceFormat(this.state.preis)} springConfig={presets.wobbly} />
                            </Button>
                            <Button className={`${article.preis.menu ? "col-12 col-md-4" : "col-6"} mt-2 border-info text-white ${this.state.disableAdd ? "bg-secondary" : "bg-info"}`}
                                size="small" variant="outlined" disabled={this.state.disableAdd} onClick={this.addWarenkorb.bind(this)}>{this.state.buttonInnerHtml}</Button>
                        </div>
                    </CardActions>
                </Card>
            </div>
        </>);
    }
}

export default withStyles(MStyle)(Article)

const getImage = (article) => {
    let img = IMAGES.find((name) => name.includes(article.artikelnummer));
    if (!img) {
        switch (article.gruppe) {
            case "pizza":
                img = DEFAULTIMAGES.find((name) => name.includes("pizza"))
                break;
            case "burger":
                img = DEFAULTIMAGES.find((name) => name.includes("burger"))
                break;
            case "salat":
                img = DEFAULTIMAGES.find((name) => name.includes("salat"))
                break;
            case "getrenk":
                img = DEFAULTIMAGES.find((name) => name.includes("getrenk"))
                break;
            case "dips":
                img = DEFAULTIMAGES.find((name) => name.includes("dips"))
                break;
            default:
                img = DEFAULTIMAGES[0]
                break;
        }
    }
    return img;
};

const getIcon = (gruppe) => {
    let icon = "";

    switch (gruppe) {
        case "pizza":
            icon = "FaPizzaSlice"
            break;
        case "burger":
            icon = "Fastfood"
            break;
        case "salat":
            icon = "GiTomato"
            break;
        case "getrenk":
            icon = "GiSodaCan"
            break;
        case "dips":
            icon = "GiFrenchFries"
            break;
        default:
            icon = "GiTomato"
            break;
    }

    return icon;
};