import React from 'react';
import { Link } from 'react-router-dom';

class Regionalitet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    render() {

        return (<>
            <button className="bestellButton"><Link to="/bestellung" className="text-white">Bestellen</Link> </button>


            <div className="container terevDiv">
                <div className="row m-0">
                    <div className="col-12 col-md-6 d-flex mt-5">

                    </div>
                    <div className="col-12 col-md-6 d-flex mt-5">
                        <div className="m-auto">
                            <h3 className="ff-1">DAS LIEBSTE VON HIER - REGIONALITÄT</h3>
                            <p>
                                Wir übernehmen Verantwortung und möchten mit
                                unserer Regionalität einen Beitrag zum Umweltschutz
                                leisten. Durch unsere regionalen Lieferanten setzen wir viele heimische Lebensmittel in unseren Gerichten ein.
                                Es sind Lebensmittel von hervorragender
                                Qualität, die nachhaltig und teilweise biologisch
                                erzeugt werden.
                            </p>

                            <h3 className="mt-5 ff-1">VERPACKUNG</h3>
                            <p>
                                Wir sagen &quot;Nein&quot; zur Verpackung aus
                                unkontrollierter Produktion.
                                Unser Papier für die Pizza- und Burgerverpackung
                                kommt aus qualifiziertem Anbau.
                                Schon heute können wir bei der Verpackung zum
                                größten Teil auf Plastik verzichten.
                                Der Umwelt zuliebe sind unsere Getränke ausschließlich aus Glasflaschen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </>);
    }
}

export default Regionalitet;
