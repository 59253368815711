import React from 'react';
import Carusel from '../components/carusel';

import bild0 from '../css/image/start/0.png';
import bild1 from '../css/image/start/1.png';
import bild2 from '../css/image/start/2.png';
import { Link } from "react-router-dom";

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    render() {
        return (<>
            <Link to="/bestellung" className="text-white"><button className="bestellButton">Bestellen</button></Link>

            <div className="animated zoomIn">

                <div className="startContainer">
                    <Carusel bilder={require('../css/image/start/carusel')()} height="500px" width="100%" />
                    <div className="p-5 text-light startContainerText">
                        <h2 className="mt-5 text-center ff-1 p-0">
                            Unser Konzept ist einfach und kreativ:<br />
                        </h2>
                        <h4 className="text-center ff-1">
                            Eine kleine, aber extrafeine Karte stellt
                            sicher, dass die besten Speisen auf den Tellern landen, {this.props.mobileVersion ? "" : <br />} mit dem Fokus auf
                            Zutaten unserer Region.
                        </h4>

                    </div>
                </div>


                <div className="container mt-5 pr-0">
                    <div className="row m-0">

                        <div className="col-12 col-md-6 ">
                            <img src={bild1} width="100%" height="auto" alt="" />
                        </div>

                        <div className="col-12 col-md-6 d-flex">
                            <div className="m-auto">
                                <h3 className="ff-1">BURGER</h3>
                                <p>
                                    Die Burger Buns werden täglich frisch vom lokalen Bäcker geliefert, das
                                    Fleisch kommt von dem besten Metzger der Stadt und wird täglich in unserem
                                    Store gewolft.
                                </p>
                            </div>

                        </div>


                        <div className="col-12 col-md-6 mt-5">
                            <img src={bild0} width="100%" height="auto" alt="" />
                        </div>
                        <div className="col-12 col-md-6 d-flex mt-5">
                            <div className="m-auto">
                                <h3 className="ff-1">CUCINA NAPOLETANA</h3>
                                <p>
                                    Du erlebst was leidenschaftliches Kochen auf Italienisch heißt. Unser original
                                    neapolitanischer Pizzateig wird täglich frisch zubereitet und
                                    mit hochwertigen Zutaten serviert. Wir backen auf echtem Feuerstein.
                                </p>
                            </div>

                        </div>



                        <div className="col-12 col-md-6 mt-5">
                            <img src={bild2} width="100%" height="auto" alt="" />
                        </div>
                        <div className="col-12 col-md-6 d-flex mt-5">
                            <div className="m-auto">
                                <h3 className="ff-1">HOUSEMADE SAUCEN</h3>
                                <p>
                                    Jedes Gericht, das wir anbieten, besticht durch seinen unverkennbaren
                                    Geschmack. Ein Hauptgrund dafür sind unsere hausgemachten, leckeren
                                    Saucen. Von unserer geheimen housemade Tomatensauce für unsere Pizza
                                    Napoletana bis hin zu der täglich frisch zubereiteten Guacamole, erhalten
                                    unsere Gerichte ihre Einzigartigkeit.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </>);
    }
}

export default Start;
