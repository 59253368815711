import Numbro from 'numbro';
import NumbroLanguages from 'numbro/dist/languages.min.js';

Object.values(NumbroLanguages).forEach(function (data) {
    Numbro.registerLanguage(data);
});

const Geld = (value, locale = 'de-DE') => {
    Numbro.setLanguage(locale);
    return Numbro(Number(value)).formatCurrency({
        thousandSeparated: true,
        mantissa: 2,
        average: false,
        spaceSeparated: true
    });
};


export default Geld;
