import React from 'react';

import Warenkorb from '../components/warenkorb';

// Article
import Article from '../components/article';
import ARTICLE from "../utils/article.json";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchList from '../components/searchList';
import Icons from '../components/icons';


class Bestellung extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: ""
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }


    setWarenkorb(article) {
        let item = Object.assign({}, article);
        let warenkorb = localStorage.getItem("warenkorb");
        warenkorb = JSON.parse(warenkorb);

        if (!warenkorb) {
            warenkorb = [];
        }

        let artikelSchonInWarenkorb = warenkorb.filter((e) => e.ARTIKELNUMMER.substring(0, e.ARTIKELNUMMER.indexOf("_")) === item.ARTIKELNUMMER || e.ARTIKELNUMMER === item.ARTIKELNUMMER);
        if (artikelSchonInWarenkorb.length) {
            item.ARTIKELNUMMER = item.ARTIKELNUMMER + "_" + (artikelSchonInWarenkorb.length + 1);
        }
        item.id = new Date().getTime();

        warenkorb.push(item);
        localStorage.setItem("warenkorb", JSON.stringify(warenkorb));
    }

    render() {
        return (<>
            <div className="warenkorb shadow border">
                <Warenkorb mobileVersion={this.props.mobileVersion} />
            </div>

            <div className="container mt-3 mb-3" style={{ minHeight: "40vh" }}>
                <SearchList onClick={this.setWarenkorb.bind(this)} ARTICLE={ARTICLE} />

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography variant="body1"><Icons icon="FaPizzaSlice" style={{ fontSize: "23pt" }} />&nbsp;&nbsp; Pizza Napoletana</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="row m-0 px-0">
                        {ARTICLE.pizza.map((item, index) => <Article article={item} key={index} onClick={this.setWarenkorb.bind(this)} />)}
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography variant="body1"><Icons icon="Fastfood" style={{ fontSize: "23pt" }} />&nbsp;&nbsp; Burger </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="row m-0 px-0">
                        {ARTICLE.burger.map((item, index) => <Article article={item} key={index} onClick={this.setWarenkorb.bind(this)} />)}
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography variant="body1"><Icons icon="GiFrenchFries" style={{ fontSize: "30pt" }} /> Fritten & Dips</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="row m-0 px-0">
                        {ARTICLE.dips.map((item, index) => <Article article={item} key={index} onClick={this.setWarenkorb.bind(this)} />)}
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography variant="body1"><Icons icon="GiTomato" style={{ fontSize: "28pt" }} /> Salate</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="row m-0 px-0">
                        {ARTICLE.salat.map((item, index) => <Article article={item} key={index} onClick={this.setWarenkorb.bind(this)} />)}
                    </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography variant="body1"><Icons icon="GiSodaCan" style={{ fontSize: "30pt" }} /> Getränke</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="row m-0 px-0">
                        {ARTICLE.getrenk.map((item, index) => <Article article={item} key={index} onClick={this.setWarenkorb.bind(this)} />)}
                    </AccordionDetails>
                </Accordion>
            </div>
        </>);
    }
}

export default Bestellung;



