import React from 'react';




class Alergene extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    render() {
        return (<div className="container mt-3">




            <div className="row m-0">
                <div className="col-12 col-md-6 mt-3">
                    <h4>Öffnungszeiten:</h4>

                    <div className="row m-0">
                        <div className="col-4">
                            <p className="m-0 noBreak"><small>Montag</small></p>
                            <p className="m-0 noBreak"><small>Dienstag</small></p>
                            <p className="m-0 noBreak"><small>Mittwoch</small></p>
                            <p className="m-0 noBreak"><small>Donnerstag</small></p>
                            <p className="m-0 noBreak"><small>Freitag</small></p>
                            <p className="m-0 noBreak"><small>Samstag</small></p>
                            <p className="m-0 noBreak"><small>Sonntag</small></p>
                        </div>



                        <div className="col-4">
                            <p className="m-0 noBreak"><small>Geschlossen</small></p>
                            <p className="m-0 noBreak"><small>17:00 – 21:30</small></p>
                            <p className="m-0 noBreak"><small>17:00 – 21:30</small></p>
                            <p className="m-0 noBreak"><small>17:00 – 21:30</small></p>
                            <p className="m-0 noBreak"><small>17:00 – 22:00</small></p>
                            <p className="m-0 noBreak"><small>13:00 – 22:00</small></p>
                            <p className="m-0 noBreak"><small>13:00 – 21:00</small></p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 mt-3">
                    <h4>Lieferbedingungen:</h4>
                    <p><b>Mindestbestellwert 18 €</b> </p>
                    <p>
                        <p><b>Anfahrtskosten</b></p>
                        Bis 5 km 2 €<br />
                        5-10 km 3 €
                    </p>
                </div>
            </div>


            <hr className="mt-5" />
            <h4 className="mt-5">Allergene und Zusatzstoffe:</h4>

            <div className="row m-0">

                <div className="col-12  mt-2">
                    <h5 >Salate: <small>Großer Bunter Salat = b, d</small></h5>
                </div>
                <div className="col-12 col-md-6 ">
                    <h5>Pizza:</h5>
                    <p className="m-0">  1: 	Margherita:		a, g,</p>
                    <p className="m-0">  2: 	Funghi:		a, g,</p>
                    <p className="m-0">  3: 	Diavola:		a, g, 2, 3,</p>
                    <p className="m-0">  4: 	Solo Salame:	a, g, 2, 3,</p>
                    <p className="m-0">  5: 	Tonno:			a, d, g,</p>
                    <p className="m-0">  6: 	Vegano:		a, f,</p>
                    <p className="m-0">  7: 	Pollo:			a, g, 2, 3,</p>
                    <p className="m-0">  8: 	Salmone e Avocado:	a, d, g</p>
                    <p className="m-0">  9:	Scampi e Rucola:	a, d, g</p>
                    <p className="m-0">  10: 	Prosciutto di Manzo:	a, g, 6</p>
                </div>

                <div className="col-12 col-md-6 ">
                    <h5>Burger:</h5>
                    <p className="m-0">20	The Classic: 		a, j, k, 2</p>
                    <p className="m-0">21	Double Cheese: 	a, g, j, k</p>
                    <p className="m-0">22 	Mr. Egg: 		a, c, g, j, k, 6</p>
                    <p className="m-0">23	Tex Mex: 		a, g, j, k, 2</p>
                    <p className="m-0">24	Mac’n’Cheese: 	a, g, j, k, 6</p>
                    <p className="m-0">25	Grilled Mushroom:	a, c, g, j, k</p>
                    <p className="m-0">26 	Italian:			a, g, h, j, k</p>
                    <p className="m-0">27 	Avocado:		a, c, g, j, k</p>
                    <p className="m-0">28	Greenland:		a, k</p>
                    <p className="m-0">29: 	Greenland Part II:	a, f, k</p>
                </div>



                <div className="col-12 col-md-6 ">
                    <h5 className="mt-3"> Getränke:</h5>
                    <p className="m-0">Cola koffeinhaltig</p>
                    <p className="m-0">Cola Zero koffeinhaltig und mit Süßungsmittel</p>

                </div>



                <div className="col-12 col-md-6 ">
                    <h5 className="mt-3">Zusatzstoffe:</h5>
                    <p className="m-0">1 mit Farbstoff, 2 mit Konservierungsstoff, 3 mit Antioxidationsmittel, 4 mit Geschmacksverstärker,</p>
                    <p className="m-0">5 geschwefelt, 6 geschwärzt, 7 mit Phosphat, 8 koffeinhaltig, 9 chininhaltig, 10 mit Süßungsmittel</p>
                </div>

                <div className="col-12 ">
                    <h5 className="mt-3"> Allergene:</h5>
                    <p>
                        a Glutenhaltiges Getreide,
                        b Krebstiere und daraus gewonnene Erzeugnisse,
                        c Eier und daraus gewonnene Erzeugnisse,
                        d Fische und daraus gewonnene Erzeugnisse,
                        e Erdnüsse und daraus gewonnene Erzeugnisse,
                        f Soja(bohnen) und daraus gewonnene Erzeugnisse,
                        g Milch und daraus gewonnene Erzeugnisse,
                        h Schalenfrüchte,
                        i Sellerie und daraus gewonnene Erzeugnisse
                        j Senf und daraus gewonnene Erzeugnisse,
                        k Sesamsamen und daraus gewonnene Erzeugnisse
                        l Schwefeldioxid und Sulfite,
                        m Lupinen und daraus gewonnene Erzeugnisse,
                        n Weichtiere und daraus gewonnene Erzeugnisse
                        Soßen und Dressing können Eier, Schalenfrüchte, Milch Schwefeldioxid Konservierungsstoffe (2) Antioxidationsmittel (3) Geschmacksverstärker (4) Süßungsmittel (10) enthalten.
                        <br /><br />
                        Leider können wir eine Kreuzkontamination durch unterschiedlichste Zutaten in unserer Küche und durch Lieferanten (z.B. Getränke) nicht zu 100 % ausschließen. Spuren von folgenden Allergenen können somit in allen Produkten enthalten sein. • Gluten • Ei • Erdnuss • Soja • Milch • Schalenfrüchte • Sellerie • Senf • Sesam • Krebstiere • Fisch • Lupine • Weichtiere • Schwefeldioxid und Sulfite
                        Die Allergene, Inhaltsstoffe und Nährwerte können sich beim Hinzufügen oder Wechseln von Zutaten ändern.
                    </p>
                </div>

            </div>


        </div>);
    }
}

export default Alergene;