import React from 'react';

import { Link } from "react-router-dom";
import FlipMove from 'react-flip-move';

import PriceFormat from '../utils/priceFormat';
import _ from 'lodash';

// Material
import { Chip, Divider, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, ListItemIcon, Badge, Button, DialogActions, Avatar } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import Icons from "./icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

import BILDER from '../css/image/article';
import DEFAULTBILDER from '../css/image/articleSymbol';
const IMAGES = BILDER();
const DEFAULTIMAGES = DEFAULTBILDER();

const MStyle = theme => ({
    AccordionSummary: {
        padding: 0
    },
    AccordionDetails: {
        padding: 0
    },

});



class Warenkorb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            warenkorb: [],
            summ: 0,
            open: false
        }
    }

    componentDidMount() {
        setInterval(() => {
            this.getWarenkorb();
        }, 1000);
    }

    getWarenkorb() {
        let warenkorb = localStorage.getItem("warenkorb");
        warenkorb = JSON.parse(warenkorb);

        if (!warenkorb) {
            warenkorb = [];
        }
        this.calcSumm(warenkorb);
    }

    calcSumm(warenkorb) {
        let summ = 0;
        for (const item of warenkorb) {
            summ += item.PREIS;
        }
        this.setState({ warenkorb: warenkorb, summ: summ });
        localStorage.setItem("summ", PriceFormat(summ))
    }

    deleteFromWarenkorb(artikelnummer) {
        let warenkorb = localStorage.getItem("warenkorb");
        warenkorb = JSON.parse(warenkorb);

        warenkorb = warenkorb.filter((item) => item.ARTIKELNUMMER !== artikelnummer);
        localStorage.setItem("warenkorb", JSON.stringify(warenkorb));
        this.getWarenkorb();
    }


    getImage(article) {
        let img = IMAGES.find((name) => name.includes(article.ARTIKELNUMMER));
        if (!img) {
            switch (article.gruppe) {
                case "pizza":
                    img = DEFAULTIMAGES.find((name) => name.includes("pizza"))
                    break;
                case "burger":
                    img = DEFAULTIMAGES.find((name) => name.includes("burger"))
                    break;
                case "salat":
                    img = DEFAULTIMAGES.find((name) => name.includes("salat"))
                    break;
                case "getrenk":
                    img = DEFAULTIMAGES.find((name) => name.includes("getrenk"))
                    break;
                case "dips":
                    img = DEFAULTIMAGES.find((name) => name.includes("dips"))
                    break;
                default:
                    img = DEFAULTIMAGES[0]
                    break;
            }
        }
        return img;

    };


    render() {
        const groupetWarenkorb = _.groupBy(this.state.warenkorb, (artikel) => artikel.NAME)
        return (<>

            <IconButton onClick={() => this.setState({ open: true })}>
                <Badge badgeContent={<span className="animated rotateIn">{this.state.warenkorb.length}</span>} color="secondary">
                    <Icons icon="FiShoppingCart" style={{ fontSize: "25pt" }} />
                </Badge>
            </IconButton>

            <Dialog
                className=""
                open={this.state.open}
                fullScreen={this.props.mobileVersion}
                fullWidth={true}
                onClose={() => this.setState({ open: false })}
            >
                <DialogTitle className="m-0 p-2">
                    <div className="d-flex w-100 m-0 p-0">
                        <IconButton className=" m-0 p-0" onClick={() => this.setState({ open: false })}>
                            <Icons icon="FiShoppingCart" style={{ fontSize: "25pt" }} />
                        </IconButton>

                        <div className="d-flex">
                            <Chip className="ml-1 bg-secondary text-white shadow-sm rounded" label={"Menge " + this.state.warenkorb.length} />
                            <Chip className="ml-1 bg-successs text-white shadow-sm rounded" label={"Gesamtpreis: " + PriceFormat(this.state.summ)} />
                        </div>

                        <IconButton className="ml-auto m-0 p-0" onClick={() => this.setState({ open: false })}>
                            <Icons icon="Close" style={{ fontSize: "25pt" }} />
                        </IconButton>
                    </div>
                    <Divider />
                </DialogTitle>
                <DialogContent style={{ minHeight: "50vh" }}>
                    {Object.keys(groupetWarenkorb).map((name, index) => {
                        const img = this.getImage(groupetWarenkorb[name][0])

                        return <Accordion key={name}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="p-1" >
                                <div className="d-flex flex-column">
                                    <Typography variant="body1" className="d-flex align-items-center"><Avatar className="mr-2" src={img} /> {groupetWarenkorb[name].length}X {name}  </Typography>
                                    <Typography variant="subtitle2" color="textSecondary" component="p">{groupetWarenkorb[name][0].BESCHREIBUNG}</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className="p-1">
                                <GroupItems article={groupetWarenkorb[name]} deleteFromWarenkorb={this.deleteFromWarenkorb.bind(this)} />
                            </AccordionDetails>
                        </Accordion>
                    })}
                </DialogContent>
                {
                    this.state.warenkorb.length ?
                        <DialogActions>
                            <Link to="/bestellForm" className="w-100">
                                <Button fullWidth color="inherit" className="bg-successs text-white"  >Jetzt Bestellen</Button>
                            </Link>
                        </DialogActions>
                        :
                        <h2 className="text-center">Warenkorb ist leer</h2>
                }
            </Dialog >
        </>);
    }
}


const menuStyle = {
    position: "absolute",
    top: "-8px",
    right: 0
}
class GroupItems extends React.Component {
    createExtras(extras = []) {
        return extras.map((item, index) => {
            return <Chip
                key={index}
                size="small"
                className="bg-success text-white ml-1 mr-1 mt-1 rounded"
                label={<small>Extra {item.name.replace("Extra", "").replace("extra", "")}</small>}
            />
        });
    }

    createMenuBeilage(beilage) {
        if (!beilage) return null;
        return Object.values(beilage).map((value) => <span key={value} style={{ fontSize: "8.5pt", display: "inline-block" }} className="text-white bg-info m-1 p-1 rounded text-wrap"><i><b> {value} </b></i></span>)
    }

    render() {
        const { article } = this.props;
        const ticketNotVisibleState = {
            transform: "translateX(-100%)",
            opacity: 0.1
        };

        return (<>
            <List className="w-100">
                <FlipMove
                    enterAnimation={{
                        from: ticketNotVisibleState,
                        to: {}
                    }}
                    leaveAnimation={{
                        from: {},
                        to: ticketNotVisibleState
                    }}

                >

                    {
                        article.map((item, i) => {
                            let M_or_S = item.single_menu ? <span className="bg-warning p-1 shadow rounded">{item.single_menu.substring(0, 1).toUpperCase()}</span> : "";
                            let primary = <small className="ml-1">{item.NAME}</small>;
                            let beilage = this.createMenuBeilage(item.MENUBEILAGE);

                            return <ListItem key={item.id} className="mt-2 border rounded shadow-sm dunkel p-0 pl-1 pr-1" >

                                <ListItemText
                                    primary={<span className="d-flex flex-wrap">{M_or_S} {primary}, {beilage}</span>}
                                    secondary={<>{this.createExtras(item.EXTRAS)}<span className="m-0"><i>{item.KOMMENTAR}</i></span></>}
                                />

                                <ListItemIcon>
                                    <div className="d-flex flex-column">
                                        <span style={menuStyle}><small>{item.single_menu}</small></span>
                                        <IconButton onClick={() => this.props.deleteFromWarenkorb(item.ARTIKELNUMMER)}>
                                            <Icons icon="Delete" style={{ color: "red" }} />
                                        </IconButton>
                                        <Chip className="bg-successs text-white shadow-sm rounded small" label={PriceFormat(item.PREIS)} size="small" />
                                    </div>

                                </ListItemIcon>
                            </ListItem>
                        })
                    }

                </FlipMove>
            </List>



        </>);
    }
}

export default withStyles(MStyle)(Warenkorb);