import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const MStyle = theme => ({
    Input: {
        marginTop: "10px"
    }
});

class Kommentar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            error: false
        }
    }

    validate(value) {
        if (value.length > 50) {
            this.setState({ error: true });
            return false;
        } else {
            this.setState({ error: false });
            return true;
        }

    }

    onChange(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (!this.validate(value)) return;

        this.setState({ value: value });
        this.props.onChange(value, name)
    }


    render() {
        return (<> <div className="pl-2 pr-2">
            <TextField
                multiline
                maxRows={6}
                fullWidth
                name="KOMMENTAR"
                label="Kommentar"
                value={this.state.value}
                error={this.state.error}
                className={this.props.classes.Input}
                onChange={this.onChange.bind(this)}
                helperText={`max. 50 Zeichen!  noch ${50 - this.state.value.length} verfügbar`}
            />
        </div>
        </>);
    }
}

export default withStyles(MStyle)(Kommentar)