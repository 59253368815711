import React from 'react';
import { Link } from "react-router-dom";
import Icon from '../components/icons';
import LOGO from '../css/image/logo.png';

const Facebook = "https://www.facebook.com/teigwerk.badoeynhausen";
const insta = "https://www.instagram.com/lecker_teigwerk/?hl=de";
const google = "https://www.google.de/search?q=teigwerk+bad+oeynhausen&source=lmns&bih=722&biw=1536&hl=de&sa=X&ved=2ahUKEwjr4dewnujwAhV6yrsIHRW3DbwQ_AUoAHoECAEQAA";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<>
            <div className="footer  text-light">
                <div className="container pt-5 pl-5 pr-5">
                    <div className="row m-0 ">
                        <div className="col-12 text-center">
                            <img src={LOGO} alt="" height="100px" width="auto" />
                        </div>
                        <div className="col-12 col-md-3 mt-5 text-right text-light d-flex ff-1 ">
                            <div className="m-auto">

                                <Link to="/kontakt">KONTAKT</Link><br />
                                <Link to="/bestellung">Bestellen</Link><br />
                                <Link to="/regionalitet">Regionalität</Link>

                            </div>
                        </div>
                        <div className="mt-3 col-12 col-md-6 text-center ff-1">
                            <div className="m-auto d-flex mt-1">
                                <div className="pointer ml-auto">
                                    <span onClick={() => window.open(Facebook, "_blank")}><Icon icon="Facebook" style={{ fontSize: "25pt" }} /></span>
                                </div>

                                <div className="pointer ml-1 mr-1">
                                    <span onClick={() => window.open(google, "_blank")}>  <Icon icon="AiFillGoogleCircle" style={{ fontSize: "25pt" }} /></span>
                                </div>
                                <div className="pointer mr-auto">
                                    <span onClick={() => window.open(insta, "_blank")}>   <Icon icon="Instagram" style={{ fontSize: "25pt" }} /></span>
                                </div>
                            </div>
                            <p className="mt-2" style={{ color: "#078065" }}>Öffnungszeiten</p>
                            <div className="row m-0 p-0 ff-2" style={{ fontSize: this.props.mobileVersion ? "9pt" : "" }}>
                                <div className="col-6 p-0">
                                    <p className="m-0 p-0 noBreak"><small>Montag</small></p>
                                    <p className="m-0 p-0 noBreak"><small>Dienstag</small></p>
                                    <p className="m-0 p-0 noBreak"><small>Mittwoch</small></p>
                                    <p className="m-0 p-0 noBreak"><small>Donnerstag</small></p>
                                    <p className="m-0 p-0 noBreak"><small>Freitag</small></p>
                                    <p className="m-0 p-0 noBreak"><small>Samstag</small></p>
                                    <p className="m-0 p-0 noBreak"><small>Sonntag</small></p>
                                </div>


                                <div className="col-6 p-0">
                                    <p className="m-0 p-0 noBreak"><small>Geschlossen</small></p>
                                    <p className="m-0 p-0 noBreak"><small>17:00 – 21:30</small></p>
                                    <p className="m-0 p-0 noBreak"><small>17:00 – 21:30</small></p>
                                    <p className="m-0 p-0 noBreak"><small>17:00 – 21:30</small></p>
                                    <p className="m-0 p-0 noBreak"><small>17:00 – 22:00</small></p>
                                    <p className="m-0 p-0 noBreak"><small>13:00 – 22:00</small></p>
                                    <p className="m-0 p-0 noBreak"><small>13:00 – 21:00</small></p>
                                </div>
                            </div>
                        </div>
                        <div className=" col-12 col-md-3 mt-5 text-light d-flex ff-1 ">
                            <div className="m-auto">
                                <Link to="/alergene">Informationen</Link><br />
                                <Link to="/datenschutz">DATENSCHUTZ</Link><br />
                                <Link to="/impressum">IMPRESSUM</Link>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="mt-5 text-center untenSeite ">
                    <p className="m-0 p-0 p-2">© {new Date().getFullYear()} www.lecker-teigwerk.de</p>
                </div>

            </div>
        </>);
    }
}

export default Footer;
