import React from 'react';

// Material
import { TextField, InputAdornment } from '@material-ui/core';
import { withStyles, fade } from '@material-ui/core/styles';

// Elements
import Icon from '../icons';


const MStyle = theme => ({
    Input: {
        '& .Mui-focused': {
            color: theme.palette.type === "dark" ? "white" : "black"
        },
        '& .Mui-disabled': {
            color: theme.palette.type === "dark" ? fade("#fafafa", 0.7) : fade("#212121", 0.65)
        },

    }
});

class TextInput extends React.Component {
    constructor(props) {
        super(props);
        let value = props.value || props.defaultValue;
        this.state = {
            value: value || "",
        };
        if (props.allowValue) {
            this.allowValue = props.allowValue.split('');
        }

    }

    onChangeInput(e) {
        let value = e.target.value;
        if (this.allowValue) {
            value = this.validate(value);
        }
        this.setState({ value: value });
        this.props.onChange(e);
    }

    validate(value) {
        for (const a of value.split("")) {
            if (!this.allowValue.find((char) => a.toLowerCase() === char.toLowerCase())) {
                value = value.replace(a, "")
            }
        }
        return value;
    }


    render() {
        const { inputRef, disabled, required, name, type, classes, label, placeholder, description, icon, inputVariant, multiline, rows, inputMode } = this.props;
        let pflichtfeld = !this.state.value && required ? <small key={"smallKey_" + name} className="text-danger p-0 m-0"><b>{"Pflichtfeld"}! </b></small> : "";

        return (<>
            <TextField
                id={name}
                fullWidth
                variant={inputVariant || "outlined"}
                InputProps={{
                    startAdornment: icon ? <InputAdornment position="start" ><Icon icon={icon} /></InputAdornment> : null,
                }}
                inputProps={{
                    inputMode: inputMode || "text"
                }}

                rows={rows || 6}
                multiline={multiline}

                inputRef={inputRef}
                disabled={disabled}
                required={required}
                name={name}
                type={type || "text"}
                className={classes.Input}
                label={label || name}
                placeholder={placeholder}

                onChange={(e) => this.onChangeInput(e)}
                value={this.state.value}
                helperText={[pflichtfeld, description]}
            />
        </>);
    }
}

export default (withStyles(MStyle)(TextInput));