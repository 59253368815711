import React from 'react';


// Material
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { withStyles, fade } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import de from "date-fns/locale/de";
import 'date-fns';

const MStyle = theme => ({
    Input: {
        '& .Mui-focused': {
            color: theme.palette.type === "dark" ? "white" : "black",
        },
        '& .Mui-disabled': {
            color: theme.palette.type === "dark" ? fade("#fafafa", 0.7) : fade("#212121", 0.65)
        },
    }
});
class DateTimeInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: props.value ? new Date(props.value) : null,
            open: false,
            error: false,
            errorText: null

        };
        this.date = {};
        this.date.target = {};
    }

    onChange(value) {
        this.date.target.value = value;
        this.date.target.name = this.props.name;
        this.props.onChange(this.date);

        let isOpen = true;
        isOpen = this.props.checkIsTeigwerkOpen(value || undefined);
        const state = {
            date: value,
            error: !isOpen,
            errorText: isOpen ? null : <b className="text-danger">Lieferzeit außerhalb unserer Öffnungszeiten!!</b>
        }
        this.setState(state);
    }


    render() {
        const { classes, name, label, description, required, disabled, placeholder, inputVariant } = this.props;
        let pflichtfeld = !this.state.date && required ? <small key={"smallKey_" + name} className="text-danger p-0 m-0"><b>{"Pflichtfeld"}! </b></small> : "";

        return <>
            < MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                    autoOk
                    error={this.state.error}

                    ampm={false}
                    fullWidth
                    inputVariant={inputVariant || "outlined"}
                    minDate={new Date("1000-01-01T03:00:00")}
                    maxDate={new Date("3000-01-01T03:00:00")}
                    minutesStep={5}

                    minDateMessage="Datum sollte nicht vor dem minimalen Datum liegen"
                    maxDateMessage="Datum sollte nicht nach dem Maximaldatum liegen"
                    invalidDateMessage="falsches Datumsformat"

                    open={this.state.open}
                    onOpen={() => this.setState({ open: true })}
                    onClose={() => this.setState({ open: false })}

                    clearable={true}
                    clearLabel="Löschen"
                    cancelLabel="Abbrechen"

                    required={required}
                    className={classes.Input}
                    disabled={disabled}
                    placeholder={placeholder || ""}
                    label={label || name}
                    format="dd.MM.yyyy HH:mm"
                    value={this.state.date}
                    onChange={(value) => this.onChange(value)}
                    helperText={this.state.errorText ? this.state.errorText : [pflichtfeld, description]}
                    shouldDisableDate={(date) => date.getDay() === 1 || (date.getTime() + 96400000) < new Date().getTime()}
                    inputProps={{
                        onClick: () => this.setState({ open: true }),
                        inputMode: "none"
                    }}
                />
            </MuiPickersUtilsProvider>
        </>;
    }
}


export default (withStyles(MStyle)(DateTimeInput));