import React from 'react';
import { AppBar, Toolbar, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import Icons from '../components/icons';
import LOGO from '../css/image/logo1.png';
const MStyle = {
    AppBar: {
        boxShadow: "none",
        backgroundColor: "white",
        /* border: "none",
         boxShadow: "none",
         margin: 0,
         padding: 0 */
    },
    Drawer: {


    }
};


class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleDrawer() {
        this.setState({ open: !this.state.open, });
    }

    close() {
        this.setState({ open: false })
    }

    render() {

        return <>
            <div className="menuButton" onClick={() => this.setState({ open: true })}>
                <Icons icon="AiOutlineMenu" />
            </div>

            <Drawer className={this.props.classes.Drawer} open={this.state.open} anchor="right" onClose={() => this.close()}>
                <div className="d-flex flex-column ff-1 drawerDiv" style={{ width: "90vw", maxWidth: "1000px" }}>

                    <NavLink onClick={() => this.close()} activeClassName="active" to="/" className="ml-5 mr-5 text-center">
                        <img src={LOGO} alt="" width="250px" height="auto" className="drawerImg" />
                    </NavLink>
                    <hr className="w-100" />

                    <NavLink onClick={() => this.close()} activeClassName="active" to="/kontakt" className="menuItem text-dark ml-3 mt-3">KONTAKT</NavLink>
                    <NavLink onClick={() => this.close()} activeClassName="active" to="/bestellung" className="menuItem text-dark ml-3 mt-3">BESTELLEN</NavLink>
                    <NavLink onClick={() => this.close()} activeClassName="active" to="/regionalitet" className="menuItem text-dark ml-3 mt-3">REGIONALITÄT</NavLink>
                    <NavLink onClick={() => this.close()} activeClassName="active" to="/alergene" className="menuItem text-dark ml-3 mt-3">Informationen</NavLink>
                    {/* <Link onClick={() => window.open(Speisekarte, "_blank")} className="menuItem text-dark ml-3 mt-3">Speisekarte</Link> */}

                    <hr className="w-100 mt-3" />

                    <span className="menuItem text-dark ml-3 mt-3 pointer" onClick={() => window.open("tel:057319815444", "_self")}>
                        <Icons icon="Call" style={{ fontSize: "25pt" }} /><br />
                        05731 98 15 444
                    </span>
                </div>
            </Drawer>

            <AppBar position="static" className={this.props.classes.AppBar}>
                <Toolbar>
                    <NavLink activeClassName="active" to="/" className="w-100 ml-5 mr-5 text-center">
                        <img src={LOGO} alt="" width="150px" height="auto" />
                    </NavLink>
                </Toolbar>
            </AppBar>
        </>

    }
}

export default withStyles(MStyle)(Navbar);