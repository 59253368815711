import React from 'react';

import Icon from '../components/icons';

import { Snackbar, IconButton, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Input from '../components/inputs';

// Moment
import Moment from 'moment';
import 'moment/locale/de';
Moment.locale("de");

const INPUTS_LIEFERN = [
    {
        "name": "ORT",
        "label": "Ort",
        "type": "text",
        "col": "col-12 col-md-6",
        "required": true,
        "description": ""
    },
    {
        "name": "PLZ",
        "label": "PLZ",
        "type": "text",
        "col": "col-12 col-md-6",
        "required": true,
        "description": "",
        "inputMode": "decimal"
    },
    {
        "name": "STRASSE",
        "label": "Straße",
        "type": "text",
        "col": "col-12 col-md-4",
        "required": true,
        "description": ""
    },

    {
        "name": "HAUSNR",
        "label": "HausNr.:",
        "type": "text",
        "col": "col-12 col-md-4",
        "required": true,
        "description": "",
        "inputMode": "text"
    },
    {
        "name": "DATUM",
        "label": "Lieferdatum (optional)",
        "type": "dateTime",
        "col": "col-12 col-md-4",
        "required": false,
        "description": "Gewünschte Lieferzeit (optional)"
    }
];
const INPUTS = [
    {
        "name": "TYP",
        "label": "Abholung / Lieferung",
        "type": "select",
        "select": [
            {
                "label": "Abholung",
                "value": "Abholung"
            },
            {
                "label": "Lieferung",
                "value": "Lieferung"
            }
        ],
        "col": "col-12 col-md-4",
        "required": true,
        "description": ""
    },
    {
        "name": "NAME",
        "label": "Name",
        "type": "text",
        "col": "col-12 col-md-4",
        "required": true,
        "description": "",
        "allowValue": "abcdefghijklmnopqrstuvwxyz1234567890ßüöä "
    },
    {
        "name": "TELEFON",
        "label": "Telefon",
        "type": "text",
        "col": "col-12 col-md-4",
        "required": true,
        "description": "",
        "inputMode": "tel"
    },
    {
        "name": "MAIL",
        "label": "E-Mail",
        "type": "email",
        "col": "col-12",
        "required": true,
        "description": "",
        "inputMode": "email"
    },
];
const TIMES = {// hier kann man in einem array (TAG) mehere zeiten eintragen zb. morgens arbeiten und abends. 
    "Sonntag": [
        {
            "von": "13:00",
            "bis": "21:00"
        }
    ],
    "Dienstag": [
        {
            "von": "17:00",
            "bis": "21:30"
        }
    ],
    "Mittwoch": [
        /*  {
             "von": "11:30",
             "bis": "14:00"
         }, */
        {
            "von": "17:00",
            "bis": "21:30"
        }
    ],
    "Donnerstag": [
        /*   {
              "von": "11:30",
              "bis": "14:00"
          }, */
        {
            "von": "17:00",
            "bis": "21:30"
        }
    ],
    "Freitag": [
        /*    {
               "von": "11:30",
               "bis": "14:00"
           }, */
        {
            "von": "17:00",
            "bis": "22:00"
        }
    ],
    "Samstag": [
        {
            "von": "13:00",
            "bis": "22:00"
        }
    ],
};
const SELECT = { ...INPUTS[0] };

class BestellForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: false,
            TYP: "Abholung",
            showAlert: false,
            loader: false
        }
        this.values = {};
    }

    componentDidMount() {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const isOpen = this.checkIsTeigwerkOpen();
        if (!isOpen) this.whenTeigwerkClose();
    }


    whenTeigwerkClose() {
        INPUTS[0].select = [SELECT.select[1]];

        INPUTS_LIEFERN[INPUTS_LIEFERN.length - 1].required = true;
        INPUTS_LIEFERN[INPUTS_LIEFERN.length - 1].label = "Lieferdatum";
        INPUTS_LIEFERN[INPUTS_LIEFERN.length - 1].description = "Gewünschte Lieferzeit";

        this.setState({ showAlert: true });
    }

    checkIsTeigwerkOpen(value = new Date()) {
        const days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];// nicht ändern!
        const actualDate = Moment(value);
        const actualTime = Moment(actualDate).format("LT");
        const day = days[actualDate.day()];

        // wenn Tag nicht passt !
        if (!TIMES[day]) {
            return false;
        }

        // wenn Zeit nicht passt !
        let teigwerkOpen = false;
        for (const item of TIMES[day]) {
            const von = item.von;
            const bis = item.bis;

            const actualNumber = Number(actualTime.replace(":", ""))
            const vonNumber = Number(von.replace(":", ""));
            const bisNumber = Number(bis.replace(":", ""));

            // wenn datum --> 'value' zwischen 'von bis' ist 
            if (actualNumber >= vonNumber && actualNumber < bisNumber) {
                teigwerkOpen = true;
                break;
            }
        }
        return teigwerkOpen;
    }

    setValue(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.values[name] = value;

        if (name === "TYP") {
            this.setState({ TYP: value });
        }
    }

    createBestellung() {
        let warenkorb = localStorage.getItem("warenkorb");
        this.values.EDATUM = new Date();
        const artikeln = warenkorb;
        const person = this.values;
        return JSON.stringify({ artikeln, person });
    }

    handleRadio(e) {
        this.setState({ radioValue: e.target.value });
    }

    sendBestellung(e) {
        e.preventDefault();
        this.setState({ loader: true });

        if (this.state.TYP === "Lieferung") {
            const summe = getSumm();
            if (!summe || summe < 18) return this.handleErrorMessages(true, "Mindestbestellwert für die Lieferung ist 18,00 € !");
        }

        if (this.values.DATUM) {
            const isOpen = this.checkIsTeigwerkOpen(this.values.DATUM);
            if (!isOpen) {
                return this.handleErrorMessages(true, "Gewünschte Lieferzeit außerhalb unserer Öffnungszeiten!");
            }

            // mindestens 40 Minuten zukunft Bestellen
            if ((this.values.DATUM.getTime()) < (new Date().getTime() + 2400000)) {
                return this.handleErrorMessages(true, "Gewünschte Lieferzeit ist zu früh!");
            }
        }

        let formData = new FormData();
        formData.append("name", this.values.NAME);
        formData.append("bestellung", this.createBestellung());

        fetch('https://lecker-teigwerk.de/sendBestellung.php', { method: "POST", body: formData })
            .then((response) => {
                return response.json()
            })

            .then(() => {
                this.handleErrorMessages(true, "Erfolgreich versendet.", "Wir bedanken uns für Ihre Bestellung.", "info")
                this.form.reset();
                localStorage.clear();
                this.setState({ icon: true }, () => window.scrollTo({ top: 0, behavior: "smooth" }));
            }).catch(() => {
                this.handleErrorMessages(true, "E-Mail konnte nicht gesendet werden", "Überprüfen Sie Ihre Internetverbindung oder rufen Sie uns an, um persönlichen Kontakt herzustellen.", "erro")
                console.log(e)
            }).finally(() => this.setState({ loader: false }));
    }

    //################################################## errorMessage
    handleErrorMessages(open, message, stack, erroClass, dauer) {
        erroClass = erroClass ? erroClass : "erro";
        dauer = dauer ? dauer : 10000;
        if (open) {
            if (message === "Unexpected end of JSON input") { message = "keine Verbindung zum Server" }
            this.setState({
                errorOpen: true,
                erroMessage: message,
                erroStack: stack,
                erroClass: erroClass,
                dauer: dauer
            });
        } else {
            this.setState({ errorOpen: false });
        }
    };
    //################################################## errorMessage

    render() {
        let summe = getSumm();

        return (<div className="container mt-5 mb-5">

            {this.state.icon ?
                <div style={{ height: "40vh" }}>
                    <div className="text-center m-auto">
                        <Icon icon="AiOutlineCheck" style={{ fontSize: "100pt" }} />

                        <h3 className="text-success">Vielen Dank für Ihre Bestellung.</h3>
                        <p>Für die zeitliche Bearbeitungsdauer prüfen Sie bitte Ihre E-Mail.</p>
                    </div>
                </div>
                :
                <>
                    {this.state.showAlert ?
                        <Alert className='mt-1' severity="error">
                            Sie bestellen gerade außerhalb unserer Öffnungszeiten. Sie haben jedoch die Möglichkeit einen gewünschten Liefertermin anzugeben.
                        </Alert> : ""
                    }
                    <Alert className='mt-1' severity="info">Füllen Sie bitte die Felder aus.</Alert>

                    {this.state.TYP === "Lieferung" && summe < 18 ?
                        <Alert className='mt-1' severity="error">
                            Mindestbestellwert für die Lieferung ist 18,00 € !
                        </Alert> : ""
                    }

                    <form onSubmit={(e) => this.sendBestellung(e)} style={{ fontFamily: "Arial" }} className=" w-100 row m-0" ref={(el) => this.form = el}>

                        {
                            INPUTS.map((item, index) => {
                                return <div className={item.col + " mt-3"} key={index + item.name}><Input {...item} onChange={(e) => this.setValue(e)} /></div>
                            })
                        }
                        {this.state.TYP && this.state.TYP === "Lieferung" ?
                            INPUTS_LIEFERN.map((item, index) => {
                                return <div className={item.col + " mt-3"} key={index + item.name}>
                                    <Input {...item} checkIsTeigwerkOpen={this.checkIsTeigwerkOpen.bind(this)} onChange={(e) => this.setValue(e)} />
                                </div>
                            })
                            : ""
                        }

                        <div className="col-12 mt-2">
                            <Button type="submit" fullWidth variant="outlined"> Bestellen  <Icon icon="Send" /></Button>
                        </div>

                    </form>
                </>
            }

            <div className={this.state.erroClass}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.errorOpen}
                    autoHideDuration={this.state.dauer}
                    onClose={() => { this.handleErrorMessages(false) }}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<div><p id="message-id">{this.state.erroMessage}</p> <span>{this.state.erroStack}</span></div>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            onClick={() => { this.handleErrorMessages(false) }}
                        >
                            <Icon icon="Close" />
                        </IconButton>,
                    ]}
                />

                <Backdrop open={this.state.loader} style={{ zIndex: 10000 }} >
                    <CircularProgress color="secondary" />
                </Backdrop>

            </div>
        </div>);
    }
}

export default BestellForm;



const getSumm = () => {
    let warenkorb = localStorage.getItem("warenkorb");
    let summ = 0;
    try {
        warenkorb = JSON.parse(warenkorb);
        for (const item of warenkorb) {
            summ += item.PREIS;
        }
    } catch (error) {
        return summ;
    }
    return summ;
}