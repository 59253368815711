import React from 'react';
import PriceFormat from '../utils/priceFormat';
import { FormControlLabel, Checkbox, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

const MStyle = theme => ({
    sub: {
        top: "-12px",
        right: "10px",
        color: "green"
    }
});

class Extras extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: false
        }
    }


    onChange(e, value) {
        this.setState({ value: value });
        this.props.onChange(this.props.extra, value)
    }

    render() {

        const { extra } = this.props;

        return (
            <FormControlLabel
                control={<Checkbox
                    size="small"
                    checked={this.state.value}
                    onChange={this.onChange.bind(this)}
                    name={extra.name}
                />}
                label={<small className="text-nowrap">{extra.name}<sub className={this.props.classes.sub}>+{PriceFormat(extra.preis)}</sub></small>}
            />);
    }
}


class ExtrasContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        if (!this.props.article.extras || !this.props.article.extras.length) return null;
        return (
            <Accordion >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >Extras</AccordionSummary>
                <AccordionDetails>
                    <div className="pl-2 pr-2">
                        {this.props.article.extras.map((item, index) => <Extras key={index}{...this.props} extra={item} />)}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
}






export default withStyles(MStyle)(ExtrasContainer)