import React from 'react';

// Material
import { TextField, MenuItem } from '@material-ui/core';



class SelectContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
        this.props.onChange(e.target.value, this.props.name);
    }



    render() {
        return (<>

            <TextField
                error={!Boolean(this.state.value)}
                fullWidth
                select
                name={this.props.name}
                label={this.props.label}
                value={this.state.value}
                onChange={this.handleChange.bind(this)}
            >
                {this.props.select.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </TextField>




        </>);
    }
}

export default SelectContainer;