import React from 'react';
import MaterialTab from '../components/materialTab';
import { Snackbar, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Komponent
import Icon from '../components/icons';


class Kontakt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.values = {};

        this.TabContent = [

            {
                title: <Icon icon="SiMailDotRu" />,
                content: <div className="m-0 row mt-5">

                    <div className="col-12 col-md-6 d-flex text-center kontaktIcon">
                        <div className="m-auto">
                            <Icon icon="GrContact" />
                        </div>
                    </div>

                    <form onSubmit={(e) => this.sendMail(e)} style={{ fontFamily: "Arial" }} className=" col-12 col-md-6 row m-0 kontaktInput" ref={(el) => this.form = el}>

                        <div className="col-12 col-md-6">
                            <input onChange={(e) => this.setValue(e)} name="VORNAME" placeholder="Vorname" required />
                        </div>
                        <div className="col-12 col-md-6">
                            <input onChange={(e) => this.setValue(e)} name="NACHNAME" placeholder="Nachname" required />
                        </div>
                        <div className="col-12">
                            <input onChange={(e) => this.setValue(e)} name="BETREFF" placeholder="Betreff" />
                        </div>
                        <div className="col-12">
                            <input onChange={(e) => this.setValue(e)} name="MAIL" type="email" placeholder="E-Mail" required />
                        </div>
                        <div className="col-12">
                            <textarea onChange={(e) => this.setValue(e)} name="TEXT" placeholder="Nachricht" required />
                        </div>
                        <div className="col-12">
                            <button type="submit"> Senden</button>
                        </div>

                    </form>


                </div>
            },

            {
                title: <Icon icon="Phone" />,
                content: <div className="text-center mt-5 nummer"><button onClick={() => window.open("tel:057319815444", "_self")}>05731 98 15 444</button></div>
            },

            {
                title: <Icon icon="HiOutlineLocationMarker" />,
                content: <div className="container">
                    <iframe
                        title="Teigwerk"
                        width="100%"
                        height="300px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?width=520&amp;height=413&amp;hl=en&amp;q=detmolderstr%2050%20Bad%20Oeynhausen+(Teigwerk)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    </iframe>
                </div>
            }




        ];

    }


    componentDidMount() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    setValue(e) {
        this.values[e.target.name] = e.target.value;
    }


    //################################################## errorMessage
    handleErrorMessages(open, message, stack, erroClass, dauer) {
        erroClass = erroClass ? erroClass : "erro";
        dauer = dauer ? dauer : 10000;
        if (open) {
            if (message === "Unexpected end of JSON input") { message = "keine Verbindung zum Server" }
            this.setState({
                errorOpen: true,
                erroMessage: message,
                erroStack: stack,
                erroClass: erroClass,
                dauer: dauer
            });
        } else {
            this.setState({ errorOpen: false });
        }
    };
    //################################################## errorMessage

    sendMail(e) {
        e.preventDefault();
        let formData = new FormData();

        formData.append("betref", this.values.BETREFF);
        formData.append("mail", this.values.MAIL);
        formData.append("nachname", this.values.NACHNAME);
        formData.append("vorname", this.values.VORNAME);
        formData.append("text", this.values.TEXT);

        fetch('https://lecker-teigwerk.de/sendMail.php', {
            method: "POST",
            body: formData
        })
            .then((response) => {
                return response.json()
            })
            .then(() => {
                this.handleErrorMessages(true, "Erfolgreich versendet.", "Wir bedanken uns für Ihre Nachricht. Sie werden schnellstmöglich eine Rückmeldung erhalten.", "info")
                this.form.reset();
            })
            .catch(() => {
                this.handleErrorMessages(true, "E-Mail konnte nicht gesendet werden", "Überprüfen Sie Ihre Internetverbindung oder rufen Sie uns an, um persönlichen Kontakt herzustellen.", "erro")
                console.log(e)
            })
    }



    render() {
        return (<>

            <button className="bestellButton"><Link to="/bestellung" className="text-white">Bestellen</Link> </button>

            <div className="ff-1 mb-5 container">
                <h3 className="text-center mt-5 mb-5">Kontakt</h3>
                <MaterialTab tabs={this.TabContent} />
            </div>

            <div className={this.state.erroClass}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.errorOpen}
                    autoHideDuration={this.state.dauer}
                    onClose={() => { this.handleErrorMessages(false) }}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<div><p id="message-id">{this.state.erroMessage}</p> <span>{this.state.erroStack}</span></div>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            onClick={() => { this.handleErrorMessages(false) }}
                        >
                            <Icon icon="Close" />
                        </IconButton>,
                    ]}
                />

            </div>
        </>);
    }
}

export default Kontakt;