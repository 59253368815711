
import React from 'react';
import Article from '../components/article';
import Icons from '../components/icons';

class SearchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputvalue: "",
            searchValue: ""
        }

        this.ALLEARTIKELN = createAllArticle(props.ARTICLE);
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.searchValue === this.state.inputvalue) return
            this.setState({ searchValue: this.state.inputvalue });
        }, 1500);
    }

    handleInput(e) {
        let value = e.target.value;
        this.setState({ inputvalue: value })
    }

    render() {

        let ARRAY = [];
        if (this.state.searchValue && this.state.inputvalue) {
            ARRAY = this.ALLEARTIKELN.filter((art) => {
                let value = this.state.searchValue.toLowerCase();

                if (art.name.toLowerCase().includes(value)) {
                    return true;
                }
                if (art.beschreibung.toLowerCase().includes(value)) {
                    return true;
                }
                if (art.begriffe.toLowerCase().includes(value)) {
                    return true;
                }
                return false;
            });
            ARRAY = ARRAY.reverse()
        }

        return (<>
            <div className="row m-0 mb-3">
                <h2 className="ff-1 col-12 col-md-6">Bestellung</h2>
                <div className="col-12 col-md-6 ">
                    <div className="searchContainer" >
                        <Icons icon="Search" />
                        <input name="search" placeholder="Suchen..." type="search" value={this.state.inputvalue} onChange={this.handleInput.bind(this)} />
                    </div>
                </div>
            </div>

            {this.state.inputvalue && !ARRAY.length ? <p className="text-secondary container mt-2">keine Ergebnisse</p> : ""}
            {this.state.inputvalue && ARRAY.length ? <p className="text-secondary container mt-2">Suchergebnisse...</p> : ""}

            <div className="row m-0">
                {ARRAY.map((item, index) => <Article {...this.props} article={item} key={index + item.artikelnummer} />)}
            </div>
        </>);
    }
}

export default SearchList;

const createAllArticle = (ARTICLE) => {
    let ARRAY = [];
    let keys = Object.keys(ARTICLE);
    for (const key of keys) {
        for (const item of ARTICLE[key]) {
            ARRAY.push(item);
        }
    }
    return ARRAY;
};