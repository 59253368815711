import React from 'react';
import './css/app.css';
import './css/animate.css';
import './css/bootstrap.css';
import { BrowserRouter as Router, Switch, Route, /* Link */ } from "react-router-dom";

// Seiten
import Start from './seiten/index';
import Kontakt from './seiten/contact';
import Impressum from './seiten/impressum';
import Datenschutz from './seiten/datenschutz';
import Regionalitet from './seiten/regionalitet';
import Bestellung from './seiten/bestellung';
import BestellForm from './seiten/bestellForm';
import Alergene from './seiten/alergene';

import Navbar from './components/navbar';
import Footer from './components/footer';

// Elemente
// import Icon from './components/icons';

import { Dialog, DialogTitle, DialogContent, Divider, DialogActions, Button } from '@material-ui/core';


class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      mobileVersion: false,
      open: false
    };
  }

  componentDidMount() {
    window.onresize = (e) => {
      if (e.target.innerWidth < 1000) {
        if (this.state.mobileVersion === true) {
          return;
        }
        this.setState({ mobileVersion: true });
      } else {
        if (this.state.mobileVersion === false) {
          return;
        }
        this.setState({ mobileVersion: false });
      }
    };

    if (window.innerWidth < 1000) {
      this.setzeState({ mobileVersion: true })
    }


  }

  setzeState(state) {
    this.setState(state);
  }


  render() {
    return (<>


      <Dialog
        className="shadow-sm pt-5 pb-5"
        open={this.state.open}
        //        maxWidth={"xs"}
        fullWidth={true}
        fullScreen={this.state.mobileVersion}
        onClose={() => this.setState({ open: false })}
      >
        <DialogTitle>Wichtige Information</DialogTitle>
        <Divider className="ml-3 mr-3" />

        <DialogContent >
          <p>
            Liebe geschätzte Gäste,

            seit Beginn dieses Jahres haben wir uns einer Reihe von Herausforderungen stellen
            müssen, die sich unmittelbar auf unsere Betriebskosten ausgewirkt haben. Dazu zählen
            bspw. die Erhöhung der Mehrwertsteuer, die Anhebung der Miete und die dazugehörigen
            Nebenkosten, der Anstieg des Mindestlohns sowie die steigenden Energiekosten,
            einschließlich Gas.<br /><br />

            In den letzten Monaten haben wir hart daran gearbeitet, die zusätzlichen Kosten, die auf uns
            zugekommen sind, selbst zu tragen. Trotz unserer Bemühungen sehen wir uns jedoch nun
            an einem Punkt angekommen, an dem wir eine Preisanpassung vornehmen müssen.<br /><br />

            In einem Versuch, die Auswirkungen auf unsere Gäste abzufedern, haben wir verschiedene
            Maßnahmen ergriffen, um die Kosten intern zu minimieren. Jedoch haben diese
            Anstrengungen nicht ausgereicht, um die steigenden Belastungen vollständig zu
            kompensieren. Wir haben auch über die Reduktion der Pizzagröße von 32 cm auf 28 cm
            oder die Verwendung von alternativen Zutaten nachgedacht.<br /><br />

            Für uns stand jedoch sehr schnell klar, dass wir weiterhin unserem Konzept treu bleiben
            wollen: eine kleine, aber extrafeine Speisekarte mit den besten und frischesten Zutaten
            unserer Region.<br /><br />

            Es ist uns wichtig, transparent mit Euch zu kommunizieren und Euch zu versichern, dass wir
            diese Entscheidung nicht leichtfertig getroffen haben. Wir haben alle Möglichkeiten sorgfältig
            abgewogen und sind zu dem Schluss gekommen, dass eine Preisanpassung ab dem 15.04
            unvermeidlich ist, um die Qualität unserer Produkte und Dienstleistungen langfristig zu
            sichern.<br /><br />

            Wir danken für die bisherige Unterstützung und Euer Verständnis in dieser Angelegenheit.<br /><br />

            Leckere Grüße,<br /><br />

            das TEIGWERK-Team
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={() => this.setState({ open: false })}>schließen</Button>
        </DialogActions>
      </Dialog>


      <Router>
        <Navbar mobileVersion={this.state.mobileVersion} />
        <Switch>
          <Route path="/impressum">
            <Impressum mobileVersion={this.state.mobileVersion} />
          </Route>
          <Route path="/datenschutz">
            <Datenschutz mobileVersion={this.state.mobileVersion} />
          </Route>
          <Route path="/kontakt">
            <Kontakt mobileVersion={this.state.mobileVersion} />
          </Route>
          <Route path="/regionalitet">
            <Regionalitet mobileVersion={this.state.mobileVersion} />
          </Route>
          <Route path="/bestellung">
            <Bestellung mobileVersion={this.state.mobileVersion} />
          </Route>
          <Route path="/bestellForm">
            <BestellForm mobileVersion={this.state.mobileVersion} />
          </Route>
          <Route path="/alergene">
            <Alergene mobileVersion={this.state.mobileVersion} />
          </Route>
          <Route path="/">
            <Start mobileVersion={this.state.mobileVersion} />
          </Route>
        </Switch>
        <Footer mobileVersion={this.state.mobileVersion} />
      </Router>
    </>
    );
  }

}

export default App;